/** @jsx jsx */
import { jsx } from "theme-ui"

export default function Wrapper({ children, style }) {
  return (
    <div
      sx={{
        maxWidth: ["100%", null, null, 1170],
        mx: "auto",
        width: "100%",
        ...style,
      }}
    >
      {children}
    </div>
  )
}
